import clsx from "clsx";

const Logo = ({ theme = "dark" }) => {
	return (
		<div
			className={clsx("Logo", "size-8 rounded-full transition ease-out", {
				"bg-red-500": theme === "dark",
				"bg-white": theme === "light",
			})}
		></div>
	);
};

export default Logo;
