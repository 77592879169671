"use client";

import subscribe from "@/subscribers/actions/subscribe";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useActionState, useState } from "react";
import { useFormStatus } from "react-dom";

const initialState = {
	success: false,
	message: "",
};

const NewsletterForm = () => {
	const [value, setValue] = useState("");
	const [state, formAction] = useActionState(subscribe, initialState);
	const { pending } = useFormStatus();

	return (
		<form action={formAction} className={clsx("flex flex-1")}>
			<div className={clsx("flex-1")}>
				<input
					type="email"
					name="email"
					placeholder="Your email address"
					required
					value={value}
					onChange={(e) => setValue(e.target.value)}
					className={clsx("m-0 w-full p-0 text-sm focus:outline-none focus:ring-0")}
				/>
				<p>{typeof state.message === "string" ? state.message : state.message?.email?.[0] || ""}</p>
			</div>
			<div className={clsx("")}>
				<button
					type="submit"
					disabled={value === ""}
					className={clsx("", {
						"opacity-50": value === "",
					})}
				>
					<ArrowRightIcon className={clsx("h-4 w-4")} />
				</button>
			</div>
		</form>
	);
};

export default NewsletterForm;
