import clsx from "clsx";
import Link from "next/link";
import { ReactNode } from "react";

type MenuLinkProps = {
	href: string;
	children: ReactNode;
	isActive?: boolean;
	[key: string]: any;
};

const MenuLink = ({ href, children, isActive, ...rest }: MenuLinkProps) => {
	return (
		<Link
			href={href}
			{...rest}
			className={clsx(
				"block w-full px-4 py-2 text-left text-sm text-zinc-700 transition ease-out hover:bg-zinc-200 hover:text-zinc-900 hover:outline-none",
				{
					"bg-zinc-200": isActive,
				}
			)}
		>
			{children}
		</Link>
	);
};

export default MenuLink;
