const userNavItems = [
  {
    key: "account",
    label: "Account Settings",
    href: "/account",
  },
  {
    key: "profiles",
    label: "Your Profiles",
    href: "/account/profiles",
  },
  {
    key: "submissions",
    label: "Submissions",
    href: "/account/submissions",
  },
  {
    key: "submit",
    label: "Submit a site",
    href: "/account/submissions/new",
  },
  {
    key: "collections",
    label: "Collections",
    href: "/collections",
  },
];

export default userNavItems;
