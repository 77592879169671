import clsx from "clsx";

type MenuButtonProps = {
	onClick: () => void;
	children: React.ReactNode;
	isActive: boolean;
};

const MenuButton = ({ onClick, children, isActive }: MenuButtonProps) => {
	return (
		<button
			onClick={onClick}
			className={clsx(
				"block w-full px-4 py-2 text-left text-sm text-zinc-700 transition ease-out hover:bg-zinc-200 hover:text-zinc-900",
				{
					"bg-zinc-200": isActive,
				}
			)}
		>
			{children}
		</button>
	);
};

export default MenuButton;
