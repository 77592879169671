"use client";

import LoadingDots from "@/components/LoadingDots";
import { useSearchContext } from "@/contexts/SearchContext";
import searchCategories from "@/lib/searchCategories";
import searchProfiles from "@/profiles/actions/searchProfiles";
import ProfileListItem from "@/profiles/components/ProfileListItem";
import type { Website } from "@/websites/actions/fetchWebsites";
import searchWebsites from "@/websites/actions/searchWebsites";
import WebsiteCard from "@/websites/components/WebsiteCard";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import * as Dialog from "@radix-ui/react-dialog";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useState } from "react";
import { usePreventScroll } from "react-aria";
import useSWR from "swr";
import { useDebounce } from "use-debounce";

const limit = 24;

const SearchModal = () => {
  const { isOpen, setIsOpen } = useSearchContext();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 300); // 300ms delay

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  // When the pathname or search params change, close the modal
  useEffect(() => {
    if (isMounted && isOpen) {
      setIsOpen(false);
    }
  }, [searchParams, pathname]);

  // Determine the icon to display based on whether the modal is open
  const Icon = isOpen ? XMarkIcon : MagnifyingGlassIcon;

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  // Prevent scrolling when the modal is open
  usePreventScroll({ isDisabled: !isOpen });

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isOpen, setIsOpen]);

  const handleOverlayClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <Dialog.Root open={isOpen} modal={false}>
      <Dialog.Trigger asChild>
        <button
          className={clsx(
            "flex justify-center items-center space-x-1.5 group:hover/primary-nav:text-zinc-500"
          )}
          onClick={handleClick}
        >
          <div>
            <Icon className={clsx("size-4")} aria-hidden />
          </div>
          <div>Search</div>
        </button>
      </Dialog.Trigger>
      <AnimatePresence>
        {isOpen && (
          <Dialog.Portal>
            <motion.div
              className={clsx(
                "SearchModal__overlay",
                "fixed inset-0 bg-black/50 z-40"
              )}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handleOverlayClick}
            ></motion.div>
            <Dialog.Content key="content" asChild>
              <div
                className={clsx(
                  "z-40 w-screen max-w-full fixed overflow-hidden border-t inset-x-0 bg-white top-header flex flex-col",
                  {
                    "h-[calc(100dvh-7rem)] bottom-0":
                      !!query && query.length >= 3,
                  }
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <div className={clsx("SearchModal__query", "px-8 border-b")}>
                  <div className="relative h-header flex items-center">
                    <div className={clsx("flex-1")}>
                      <input
                        type="text"
                        className={clsx(
                          "text-sm lg:text-xl border-0 w-full flex p-0 m-0 focus:outline-none focus:ring-0"
                        )}
                        placeholder="Search..."
                        autoFocus
                        value={query}
                        onChange={(e) => {
                          setQuery(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                {!!debouncedQuery && debouncedQuery.length >= 3 && (
                  <_Results query={debouncedQuery} />
                )}
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        )}
      </AnimatePresence>
    </Dialog.Root>
  );
};

const _Results = ({ query }: { query: string }) => {
  const shouldSearch = !!query && query.length >= 3;

  const { data: websitesResponse, isLoading } = useSWR(
    shouldSearch ? ["searchWebsites", query] : null,
    () => searchWebsites({ query, limit }),
    {
      keepPreviousData: true,
    }
  );

  const websites = websitesResponse?.websites || [];

  return (
    <div className={clsx("SearchModal__results")}>
      <div className={clsx("flex flex-col lg:flex-row relative")}>
        <ScrollArea.Root className="flex-1 lg:h-[calc(100vh-6rem-8rem)]">
          <ScrollArea.Viewport className="w-full h-full">
            <div className={clsx("border-b lg:border-b-0")}>
              {websites.length > 0 && (
                <div className={clsx("px-8 py-8 space-y-4")}>
                  <div
                    className={clsx("grid grid-cols-2 lg:grid-cols-3 gap-8")}
                  >
                    {websites.map((website) => {
                      return (
                        <WebsiteCard
                          key={website.id}
                          website={website as unknown as Website}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {!isLoading && websites.length === 0 && (
                <div className={clsx("px-8 py-8 text-zinc-500")}>
                  No websites.{" "}
                  <Link
                    href="/websites"
                    className={clsx(
                      "text-zinc-700 underline underline-offset-2"
                    )}
                  >
                    See all.
                  </Link>
                </div>
              )}
              {isLoading && (
                <div className={clsx("px-8 py-8 text-zinc-500")}>
                  <LoadingDots />
                </div>
              )}
            </div>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="flex select-none touch-none p-0.5 bg-black/5 transition-colors duration-150 ease-out hover:bg-black/10 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="flex-1 bg-black/40 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>

        <div className={clsx("lg:w-72 shrink-0")}>
          <ScrollArea.Root className="h-[calc(100vh-6rem-6rem)]">
            <ScrollArea.Viewport className="w-full h-full">
              <div className={clsx("border-l")}>
                <div className={clsx("border-b")}>
                  <_Categories query={query} />
                </div>
                <div>
                  <_Profiles query={query} />
                </div>
              </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              className="flex select-none touch-none p-0.5 bg-black/5 transition-colors duration-150 ease-out hover:bg-black/10 data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
              orientation="vertical"
            >
              <ScrollArea.Thumb className="flex-1 bg-black/40 rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </div>
      </div>
    </div>
  );
};

const _Categories = ({ query }: { query: string }) => {
  const shouldSearch = !!query && query.length >= 3;

  const { data: categories = [], isLoading } = useSWR(
    shouldSearch ? ["searchCategories", query] : null,
    () => searchCategories({ query, limit }),
    {
      keepPreviousData: true,
    }
  );

  return (
    <div className={clsx("space-y-4 p-8")}>
      <div className={clsx("text-xl space-x-1.5")}>
        <span>{categories.length === 1 ? "Category" : "Categories"}</span>
        <span className={clsx("text-zinc-400")}>{categories.length}</span>
      </div>
      {categories.length > 0 && (
        <div className={clsx("flex flex-col space-y-2")}>
          {categories.map((category) => {
            return (
              <Link
                key={category.id}
                href={`/websites?category=${category.slug}`}
              >
                {category.name}
              </Link>
            );
          })}
        </div>
      )}
      {!isLoading && categories.length === 0 && (
        <div className={clsx("text-zinc-500")}>
          No categories.{" "}
          <Link
            href="/websites"
            className={clsx("text-zinc-700 underline underline-offset-2")}
          >
            See all.
          </Link>
        </div>
      )}

      {isLoading && (
        <div className={clsx("text-zinc-500")}>
          <LoadingDots />
        </div>
      )}
    </div>
  );
};

const _Profiles = ({ query }: { query: string }) => {
  const shouldSearch = !!query && query.length >= 3;

  const { data: profiles = [], isLoading } = useSWR(
    shouldSearch ? ["searchProfiles", query] : null,
    () => searchProfiles({ query, limit, offset: 0 }),
    {
      keepPreviousData: true,
    }
  );

  return (
    <div className={clsx("space-y-4 p-8")}>
      <div className={clsx("text-xl space-x-1.5")}>
        <span>{profiles.length === 1 ? "Profile" : "Profiles"}</span>
        <span className={clsx("text-zinc-400")}>{profiles.length}</span>
      </div>
      {profiles.length > 0 && (
        <div className={clsx("flex flex-col space-y-4")}>
          {profiles.map((profile) => {
            return (
              <div key={profile.id}>
                <ProfileListItem profile={profile} />
              </div>
            );
          })}
        </div>
      )}
      {profiles.length === 0 && (
        <div className={clsx("text-zinc-500")}>
          No profiles.{" "}
          <Link
            href="/profiles"
            className={clsx("text-zinc-700 underline underline-offset-2")}
          >
            See all.
          </Link>
        </div>
      )}
      {isLoading && (
        <div className={clsx("text-zinc-500")}>
          <LoadingDots />
        </div>
      )}
    </div>
  );
};

export default SearchModal;
