
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"26990cb4a45954aa2b2e6af163d9e98dbeba1864":"searchProfiles","296d7063929fbe3f10e1a27009f4a1a495f96dc8":"$$ACTION_0","f802cd3b40a2a831e35fc4fbea1cb47a43d51730":"default"} */ export default createServerReference("f802cd3b40a2a831e35fc4fbea1cb47a43d51730");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var searchProfiles = createServerReference("26990cb4a45954aa2b2e6af163d9e98dbeba1864");

