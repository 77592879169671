
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"1ee99f7cfc497fc9f7c3271aa4e746ce15c521b9":"$$ACTION_0","69ca319da81b5f0ffd52995a58c0329a4273cf78":"searchCategories","7337bf4433c2e96ffa009e9038564952f0eedd2e":"default"} */ export default createServerReference("7337bf4433c2e96ffa009e9038564952f0eedd2e");
import { createServerReference } from "private-next-rsc-action-client-wrapper";
export var searchCategories = createServerReference("69ca319da81b5f0ffd52995a58c0329a4273cf78");

