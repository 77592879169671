"use client";

import { useHeaderContext } from "@/contexts/HeaderContext";
import { useMenuContext } from "@/contexts/MenuContext";
import { Bars2Icon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { forwardRef } from "react";

type MenuToggleProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const MenuToggle = forwardRef<HTMLButtonElement, MenuToggleProps>((props, forwardedRef) => {
	const { transparent } = useHeaderContext();
	const { open } = useMenuContext();

	return (
		<button
			{...props}
			className={clsx(
				"MenuToggle",
				"fixed right-8 top-3 z-50 flex size-8 shrink-0 items-center justify-center rounded-full lg:hidden",
				{
					"text-zinc-50": transparent,
					"text-zinc-900": !transparent || open,
				}
			)}
			ref={forwardedRef}
			aria-label="Toggle menu"
		>
			{!open && <Bars2Icon className={clsx("size-6")} />}
			{!!open && <XMarkIcon className={clsx("size-6")} />}
		</button>
	);
});

MenuToggle.displayName = "MenuToggle";

export default MenuToggle;
