
import { callServer } from 'next/dist/client/app-call-server'

function __build_action__(action, args) {
  return callServer(action.$$id, args)
}

/* __next_internal_action_entry_do_not_use__ {"8184637a6c6b185682dda4de965f75150784f666":"default"} */ export default createServerReference("8184637a6c6b185682dda4de965f75150784f666");
import { createServerReference } from "private-next-rsc-action-client-wrapper";

