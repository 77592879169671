import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/components/HeaderMobile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/components/Logotype.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/components/Menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/components/NewsletterForm.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/components/PrimaryNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/client/src/components/UserMenu.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
