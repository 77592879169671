"use client";

import MenuToggle from "@/components/MenuToggle";
import { useAuthContext } from "@/contexts/AuthContext";
import { useMenuContext } from "@/contexts/MenuContext";
import { easeInOutQuad } from "@/lib/easing";
import { primaryItems } from "@/lib/navigation";
import userNavItems from "@/lib/userNavItems";
import * as Dialog from "@radix-ui/react-dialog";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

const Menu = () => {
	const { user, status, reset } = useAuthContext();

	const { open, setOpen } = useMenuContext();

	// When the route changes, close the menu
	const pathname = usePathname();

	useEffect(() => {
		setOpen(false);
	}, [pathname]);

	return (
		<Dialog.Root open={open} onOpenChange={setOpen} modal={false}>
			<Dialog.Trigger asChild>
				<MenuToggle />
			</Dialog.Trigger>

			<AnimatePresence>
				{open && (
					<Dialog.Portal forceMount>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ ease: easeInOutQuad, duration: 0.5 }}
							className={clsx("fixed inset-0 z-40 block bg-black bg-opacity-50 lg:!hidden")}
						/>

						<Dialog.Content asChild>
							<motion.div
								initial={{ x: "100%" }}
								animate={{ x: "0%" }}
								exit={{ x: "100%" }}
								transition={{ ease: easeInOutQuad, duration: 0.5 }}
								className={clsx(
									"fixed inset-x-0 bottom-0 top-0 z-40 block translate-x-1/2 pl-24 lg:hidden"
								)}
							>
								<div className={clsx("block min-h-full bg-white py-12 lg:hidden")}>
									<nav className={clsx("px-8")}>
										<ul className={clsx("space-y-2")}>
											{primaryItems.map((item) => {
												return (
													<li key={item.key}>
														<Link href={item.href} className={"block text-xl"}>
															{item.label}
														</Link>
													</li>
												);
											})}
										</ul>
									</nav>
									<nav className={clsx("mt-8 border-t px-8 py-8")}>
										{status === "unauthenticated" && <_SignInOption />}
										{status === "authenticated" && !!user && <_UserOptions />}
									</nav>
								</div>
							</motion.div>
						</Dialog.Content>
					</Dialog.Portal>
				)}
			</AnimatePresence>
		</Dialog.Root>
	);
};

const _SignInOption = () => {
	return (
		<Link href="/signin" className={"block text-base"}>
			<div>Sign In</div>
		</Link>
	);
};

const _UserOptions = () => {
	const { user, status, reset } = useAuthContext();

	const { setOpen } = useMenuContext();

	if (status === "unauthenticated") {
		return null;
	}

	return (
		<>
			<div className={clsx("mb-4")}>Hi {user?.firstName}</div>

			<ul className={clsx("space-y-2")}>
				{userNavItems.map((item) => {
					return (
						<li key={item.key}>
							<Link href={item.href} className={"block text-base"}>
								{item.label}
							</Link>
						</li>
					);
				})}
				<li className={clsx("pt-2")}>
					<button
						onClick={() => {
							reset();
							setOpen(false);
						}}
						className={clsx("block text-base text-zinc-500")}
					>
						Sign Out
					</button>
				</li>
			</ul>
		</>
	);
};

export default Menu;
