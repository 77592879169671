type PrimaryItem = {
  key: string;
  href: string;
  label: string;
};

export const primaryItems: PrimaryItem[] = [
  {
    key: "websites",
    href: "/websites",
    label: "Websites",
  },
  {
    key: "profiles",
    href: "/profiles",
    label: "Profiles",
  },
  {
    key: "about",
    href: "/about",
    label: "About",
  },
];
