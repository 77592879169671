"use client";

import LoadingDots from "@/components/LoadingDots";
import MenuButton from "@/components/MenuButton";
import MenuLink from "@/components/MenuLink";
import { useAuthContext } from "@/contexts/AuthContext";
import userNavItems from "@/lib/userNavItems";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";
import Link from "next/link";

type UserMenuProps = {
  signOut: () => Promise<void>;
};

const baseClassName = clsx(
  "bg-zinc-100 h-8 px-3 flex items-center justify-center rounded-full group transition ease-out"
);

const UserMenu = ({ signOut }: UserMenuProps) => {
  const { user, status, reset } = useAuthContext();

  if (status === "loading")
    return (
      <div
        className={clsx(
          baseClassName,
          "size-10 flex items-center justify-center"
        )}
      >
        <LoadingDots />
      </div>
    );

  if (status === "unauthenticated") {
    return (
      <Link
        href="/signin"
        className={clsx(
          "SiteHeader__authLink",
          baseClassName,
          "hover:bg-zinc-900 space-x-2 hover:text-zinc-50"
        )}
      >
        <div
          className={clsx(
            "w-2.5 h-2.5 bg-zinc-900 rounded-full group-hover:bg-zinc-200 transition ease-out"
          )}
        ></div>
        <div>Sign In</div>
      </Link>
    );
  }

  if (status === "authenticated" && !!user) {
    return (
      <DropdownMenu.Root modal={false}>
        <DropdownMenu.Trigger
          className={clsx(
            "UserMenu__button",
            baseClassName,
            "space-x-1.5 pr-3.5 pl-4 data-[state=open]:bg-zinc-900 data-[state=open]:text-zinc-50 data-[state=closed]:hover:bg-zinc-700 hover:text-zinc-50"
          )}
        >
          <div className={clsx("whitespace-nowrap")}>Hi {user.firstName}</div>
          <div className={clsx("data-[state=open]:rotate-180")}>
            <ChevronDownIcon className={clsx("w-3 h-3")} aria-hidden="true" />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={clsx(
              "border border-zinc-200 w-48 rounded-md z-50 py-1 bg-white focus:outline-none"
            )}
            side="top"
            sideOffset={5}
            collisionPadding={24}
          >
            <DropdownMenu.Group>
              {userNavItems.map((item) => {
                return (
                  <DropdownMenu.Item key={item.key} asChild>
                    <MenuLink href={item.href}>{item.label}</MenuLink>
                  </DropdownMenu.Item>
                );
              })}
            </DropdownMenu.Group>
            <DropdownMenu.Separator className={clsx("border-b")} />
            <DropdownMenu.Item asChild>
              <MenuButton
                isActive={false}
                onClick={async () => {
                  await signOut();
                  reset();
                }}
              >
                Sign Out
              </MenuButton>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  }

  return <div>{status}</div>;
};

export default UserMenu;
