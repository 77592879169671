import type { SearchedProfile } from "@/profiles/actions/searchProfiles";
import clsx from "clsx";
import Link from "next/link";

const ProfileListItem = ({ profile }: { profile: SearchedProfile }) => {
  return (
    <Link href={`/profiles/${profile.id}-${profile.slug}`}>
      <div className={clsx("flex")}>
        <div>
          <div className={clsx("flex items-center space-x-1.5")}>
            <div>{profile.name}</div>{" "}
            <div className={clsx("token")}>{profile.approvedCreditsCount}</div>
          </div>
          {profile.addresses.map((address, _index) => {
            return (
              <div key={address.id} className={clsx("text-zinc-500")}>
                {address.cityName}, {address.country?.name}
              </div>
            );
          })}
        </div>
      </div>
    </Link>
  );
};

export default ProfileListItem;
