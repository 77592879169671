export const easeInSine = [0.47, 0, 0.745, 0.715];
export const easeOutSine = [0.39, 0.575, 0.565, 1];
export const easeInOutSine = [0.445, 0.05, 0.55, 0.95];

export const easeInQuad = [0.55, 0.085, 0.68, 0.53];
export const easeOutQuad = [0.25, 0.46, 0.45, 0.94];
export const easeInOutQuad = [0.455, 0.03, 0.515, 0.955];

export const easeInCubic = [0.55, 0.055, 0.675, 0.19];
export const easeOutCubic = [0.215, 0.61, 0.355, 1];
export const easeInOutCubic = [0.65, 0.05, 0.36, 1];

export const easeInQuart = [0.895, 0.03, 0.685, 0.22];
export const easeOutQuart = [0.165, 0.84, 0.44, 1];
export const easeInOutQuart = [0.77, 0, 0.175, 1];

export const easeInQuint = [0.755, 0.05, 0.855, 0.06];
export const easeOutQuint = [0.23, 1, 0.32, 1];
export const easeInOutQuint = [0.86, 0, 0.07, 1];

export const easeInExpo = [0.95, 0.05, 0.795, 0.035];
export const easeOutExpo = [0.19, 1, 0.22, 1];
export const easeInOutExpo = [1, 0, 0, 1];

export const easeInCirc = [0.6, 0.04, 0.98, 0.335];
export const easeOutCirc = [0.075, 0.82, 0.165, 1];
export const easeInOutCirc = [0.785, 0.135, 0.15, 0.86];

export const easeInBack = [0.6, -0.28, 0.735, 0.045];
export const easeOutBack = [0.175, 0.885, 0.32, 1.275];
export const easeInOutBack = [0.68, -0.55, 0.265, 1.55];
