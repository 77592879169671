"use client";

import { useSearchContext } from "@/contexts/SearchContext";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

const SearchToggle = () => {
  const { isOpen, setIsOpen } = useSearchContext();

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const Icon = isOpen ? XMarkIcon : MagnifyingGlassIcon;

  return (
    <button
      className={clsx(
        "SearchToggle",
        "flex justify-center items-center space-x-1.5 size-8"
      )}
      onClick={handleClick}
      aria-label="Toggle search"
    >
      <Icon className={clsx("size-5")} aria-hidden />
    </button>
  );
};

export default SearchToggle;
