"use client";

import { primaryItems } from "@/lib/navigation";
import SearchModal from "@/search/components/SearchModal";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import clsx from "clsx";
import NextLink from "next/link";
import { usePathname } from "next/navigation";

const PrimaryNavigation = ({ items = [] }): JSX.Element => {
	const pathname = usePathname();

	return (
		<NavigationMenu.Root className={clsx("PrimaryNavigation", "group")}>
			<NavigationMenu.List
				className={clsx("PrimaryNavigation__items", "group/primary-nav flex space-x-8")}
			>
				{primaryItems.map((item) => {
					const isActive = pathname.startsWith(item.href);
					return (
						<NavigationMenu.Item key={item.key} asChild>
							<Link
								href={item.href}
								isActive={isActive}
								className={clsx("flex items-center space-x-2 transition ease-out", {
									"text-zinc-950": isActive,
									"text-zinc-400 hover:text-zinc-950": !isActive,
								})}
							>
								<span>{item.label}</span>
							</Link>
						</NavigationMenu.Item>
					);
				})}
				<NavigationMenu.Item asChild>
					<SearchModal />
				</NavigationMenu.Item>
			</NavigationMenu.List>

			<NavigationMenu.Viewport />
		</NavigationMenu.Root>
	);
};

const Link = ({
	href,
	isActive,
	...props
}: {
	href: string;
	isActive: boolean;
} & React.ComponentPropsWithoutRef<typeof NavigationMenu.Link>) => {
	return (
		<NextLink href={href} passHref legacyBehavior>
			<NavigationMenu.Link active={isActive} {...props} />
		</NextLink>
	);
};

export default PrimaryNavigation;
