"use client";

import Logotype from "@/components/Logotype";
import { useHeaderContext } from "@/contexts/HeaderContext";
import SearchToggle from "@/search/components/SearchToggle";
import clsx from "clsx";
import Link from "next/link";

const HeaderMobile = () => {
	const { transparent } = useHeaderContext();

	return (
		<header
			className={clsx(
				"HeaderMobile",
				"fixed inset-x-0 top-0 z-30 w-screen max-w-full px-8 transition ease-out lg:hidden",
				{
					"bg-white": !transparent,
					"bg-transparent": transparent,
				}
			)}
		>
			<div className={clsx("HeaderMobile__content", "h-header flex items-center")}>
				<div className={clsx("HeaderMobile__logotypeContainer", "")}>
					<Link href="/" className={clsx("HeaderMobile__logotype", "flex items-center space-x-4")}>
						<div>
							<Logotype theme={transparent ? "light" : "dark"} />
						</div>
						<div className={clsx("hidden")}>Siteinspire</div>
					</Link>
				</div>
				<div className={clsx("absolute right-20")}>
					<SearchToggle />
				</div>
			</div>
		</header>
	);
};

export default HeaderMobile;
